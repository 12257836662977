import { createSlice } from "@reduxjs/toolkit";
import axios from "../../utils/axios";

const initialState = {
  loading: false,
  error: false,
  filters_data: {},
  data: {
    brands: [],
    status: '',
    message: '',
  },
};

const slice = createSlice({
  name: "brands",
  initialState,
  reducers: {
    startLoading(state) {
      state.loading = true;
    },
    hasError(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    brands(state, action) {
      state.loading = false;
      state.data = {
        ...action.payload,
        ...state.data,
        brands: action.payload.brands.map((x) => {
          return { ...x, key: x.id };
        }),
        total: action.payload.total,
      };
    },
    sendMessage(state, action) {
      state.loading = false;
      state.data = {
        ...state.data,
        status: action.payload.data.status,
        message: action.payload.data.message,
      }
    },
    resetMessage(state, action) {
      state.data = {
        ...state.data,
        status: '',
        message: '',
      }
    }
  },
});

export default slice.reducer;

export function getBrands({ filters = {} }) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      let url = `/brands/all?`;
      let count = 0;
      for (const property in filters) {
        url += count == 0 ? `${property}=${filters[property]}` : `&${property}=${filters[property]}`;
        count++;
      }
      const response = await axios.get(url);
      dispatch(slice.actions.brands(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function createBrand(data) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      let url = `/brands/save`;
      const response = await axios.post(url, data);
      dispatch(slice.actions.sendMessage(response));
      setTimeout(() => {
        dispatch(slice.actions.resetMessage());
      }, 5000);
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function updateBrand(data) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      let url = `/brands/update`;
      const response = await axios.put(url, data);
      dispatch(slice.actions.sendMessage(response));
      setTimeout(() => {
        dispatch(slice.actions.resetMessage());
      }, 5000);
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}