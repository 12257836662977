import React, { useState } from "react";
import { Layout, Menu, Modal, Form, Input, Button, message, Divider, Row, Col, Dropdown } from "antd";
import { UserOutlined, LockOutlined, InfoCircleOutlined, MailOutlined } from "@ant-design/icons";
import { useDispatch } from 'react-redux';
import { updatePassAndName } from '../../redux/slices/users';
import mainLogo from "../../assets/logo/icononly_transparent_nobuffer.png";
import useAuth from "../../hooks/useAuth";
import { ButtonNotification } from "../notificationsNormalize/ButtonNotification";
import { ButtonUpdates } from "../updatesComponent/ButtonUpdate";
import useMediaQuery from "../../hooks/useMediaQuery";
import Contacto from "../emailSupport/Contacto";

const Header = ({ toggleSidebar, collapsed }) => {
  const { user, logout, updateUserContext } = useAuth();
  const { Header } = Layout;
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const isMobile = useMediaQuery("max-width", 790);
  const [isContactModalVisible, setIsContactModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = async () => {
    try {
      setLoading(true);
      const values = await form.validateFields();
      if (values.newPassword !== values.confirmPassword) {
        message.error('Las nuevas contraseñas no coinciden');
        setLoading(false);
        return;
      }
      await dispatch(updatePassAndName(values));
      updateUserContext({ ...user, name: values.name });
      message.success('Usuario actualizado correctamente');
      setIsModalVisible(false);
      form.resetFields();
    } catch (error) {
      message.error(error.message || 'Error al actualizar el usuario');
    } finally {
      setLoading(false);
    }
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const showContactModal = () => {
    setIsContactModalVisible(true);
  };

  const handleContactModalClose = () => {
    setIsContactModalVisible(false);
  };

  return (
    <Header
      style={{
        height: "70px",
        padding: isMobile ? "0 16px" : "0 60px",
        position: "sticky",
        top: 0,
        zIndex: 1000,
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        backgroundColor: "#001529"
      }}
    >
      <div className="logo" style={{ display: "flex", alignItems: "center" }}>
        <img
          src={mainLogo}
          style={{
            width: isMobile ? "40px" : "60px",
            height: isMobile ? "45px" : "65px",
            padding: "2px"
          }}
          alt="logo"
        />
      </div>

      <div style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        gap: "12px"
      }}>
        <ButtonUpdates />
        <ButtonNotification user={user} />

        <Dropdown
          overlay={
            <Menu
              theme="dark"
              style={{
                padding: "4px 0",
                minWidth: "160px",
                backgroundColor: "#001529",
                border: "1px solid rgba(255,255,255,0.1)"
              }}
            >
              <Menu.Item
                key="profile"
                onClick={showModal}
                style={{
                  padding: "8px 16px",
                  height: "40px",
                  lineHeight: "24px"
                }}
              >
                <UserOutlined style={{ marginRight: "8px" }} />
                Editar Perfil
              </Menu.Item>
              <Menu.Item
                key="contact"
                onClick={showContactModal}
                style={{
                  padding: "8px 16px",
                  height: "40px",
                  lineHeight: "24px"
                }}
              >
                <MailOutlined style={{ marginRight: "8px" }} />
                Contáctanos
              </Menu.Item>
              <Menu.Item
                key="logout"
                onClick={logout}
                style={{
                  padding: "8px 16px",
                  height: "40px",
                  lineHeight: "24px"
                }}
              >
                <LockOutlined style={{ marginRight: "8px" }} />
                Salir
              </Menu.Item>
            </Menu>
          }
          placement="bottomRight"
          trigger={["click"]}
          overlayStyle={{
            width: "auto",
            minWidth: "160px"
          }}
        >
          <div
            style={{
              color: "#fff",
              cursor: "pointer",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              padding: "0 8px",
              height: "40px"
            }}
          >
            <UserOutlined style={{ fontSize: "22px", marginRight: "8px" }} />
            {!isMobile && <span>{user?.name}</span>}
          </div>
        </Dropdown>
      </div>

      <Modal
        title="Editar Perfil"
        open={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        confirmLoading={loading}
        centered
        width={isMobile ? "90%" : "400px"}
        footer={[
          <Button
            key="cancel"
            onClick={handleCancel}
            style={{
              borderRadius: '20px',
              transition: 'all 0.3s'
            }}
          >
            Cancelar
          </Button>,
          <Button
            key="submit"
            type="primary"
            loading={loading}
            onClick={handleOk}
            style={{
              borderRadius: '20px',
              transition: 'all 0.3s'
            }}
          >
            Guardar
          </Button>,
        ]}
        bodyStyle={{
          borderRadius: '20px',
          padding: isMobile ? '16px' : '20px'
        }}
        style={{
          borderRadius: '20px',
          overflow: 'hidden',
          boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
          transition: 'all 0.3s'
        }}
      >
        <Form
          form={form}
          layout="vertical"
          initialValues={{ name: user?.name }}
          style={{ width: "100%" }}
        >
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <Form.Item
                name="name"
                label="Nombre Completo"
                rules={[{ required: true, message: 'Por favor ingrese su nombre completo' }]}
              >
                <Input
                  prefix={<UserOutlined />}
                  style={{
                    borderRadius: '20px',
                    transition: 'all 0.3s'
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
          <Divider style={{ margin: "12px 0" }} />
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <Form.Item
                name="currentPassword"
                label="Contraseña Actual"
                rules={[{ required: true, message: 'Por favor ingrese su contraseña actual' }]}
                tooltip={{ title: 'Ingrese su contraseña actual', icon: <InfoCircleOutlined /> }}
              >
                <Input.Password
                  prefix={<LockOutlined />}
                  style={{
                    borderRadius: '20px',
                    transition: 'all 0.3s'
                  }}
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                name="newPassword"
                label="Nueva Contraseña"
                rules={[{ required: true, message: 'Por favor ingrese su nueva contraseña' }]}
                tooltip={{ title: 'Ingrese una nueva contraseña segura', icon: <InfoCircleOutlined /> }}
              >
                <Input.Password
                  prefix={<LockOutlined />}
                  style={{
                    borderRadius: '20px',
                    transition: 'all 0.3s'
                  }}
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                name="confirmPassword"
                label="Confirmar Nueva Contraseña"
                rules={[{ required: true, message: 'Por favor confirme su nueva contraseña' }]}
              >
                <Input.Password
                  prefix={<LockOutlined />}
                  style={{
                    borderRadius: '20px',
                    transition: 'all 0.3s'
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>

      <Contacto visible={isContactModalVisible} onClose={handleContactModalClose} />
    </Header>
  );
};

export default Header;
