import React, { useEffect } from "react";
import { Layout, Menu, Drawer } from "antd";
import {
  SolutionOutlined,
  ProfileOutlined,
  ToolOutlined,
  FolderOutlined,
  PieChartOutlined,
  DatabaseOutlined,
  CarOutlined,
  TableOutlined,
  UserOutlined,
  TagOutlined,
  ShopOutlined,
  HomeOutlined,
  NotificationOutlined,
  SettingOutlined,
  UploadOutlined,
  CloudUploadOutlined,
  UnorderedListOutlined,
  LoginOutlined
} from "@ant-design/icons";
import { useLocation, useNavigate } from "react-router-dom";
import { GrUpdate } from "react-icons/gr";
import useAuth from "../../hooks/useAuth";

const SiderMenu = ({ isMobile, collapsed, setCollapsed }) => {
  const { access } = useAuth();
  const { Sider } = Layout;
  const { SubMenu } = Menu;
  const navigate = useNavigate();
  const { pathname } = useLocation();

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 768) {
        setCollapsed(true);
      }
    };

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, [setCollapsed]);

  const toggleCollapsed = () => {
    setCollapsed(!collapsed);
  };

  const getMenuKey = (pathname) => {
    switch (pathname) {
      case "/estado-cargas":
        return "13";
      case "/ventas-charts":
        return "0";
      case "/ventas":
        return "1";
      case "/stock":
        return "6";
      case "/ot-charts":
        return "2";
      case "/ot":
        return "3";
      case "/repuestos":
        return "5";
      case "/repuestos-charts":
        return "18";
      case "/file-upload":
        return "14";
      case "/conexion-usuarios":
        return "15";
      case "/users":
        return "8";
      case "/updates":
        return "23";
      default:
        return "0";
    }
  };

  const menuContent = (
    <Menu
      mode="inline"
      defaultSelectedKeys={[getMenuKey(pathname)]}
      theme="dark"
      style={{
        height: "100%",
        borderRight: 0,
        background: "linear-gradient(180deg, #001529 0%, #004d80 100%)",
        transition: "background 0.3s ease",
        fontFamily: "'Roboto', sans-serif",
        fontSize: "16px"
      }}
    >
      {access.includes(13) ? (
        <SubMenu key="home" icon={<HomeOutlined style={{ fontSize: '20px' }} />} title="Inicio">
          {access.includes(0) ? (
            <Menu.Item
              key="13"
              icon={<NotificationOutlined style={{ fontSize: '20px' }} />}
              onClick={() => navigate("/estado-cargas")}
              style={{
                transition: 'all 0.3s',
                ':hover': {
                  color: '#1890ff',
                  backgroundColor: 'rgba(255, 255, 255, 0.1)',
                  boxShadow: '0 0 5px rgba(255, 255, 255, 0.5)'
                }
              }}
            >
              Estado de cargas
            </Menu.Item>
          ) : (
            ""
          )}
        </SubMenu>
      ) : (
        ""
      )}
      {access.includes(1) ? (
        <SubMenu key="sub1" icon={<CarOutlined style={{ fontSize: '20px' }} />} title="Venta Vehículo">
          {access.includes(2) ? (
            <Menu.Item
              key="0"
              icon={<PieChartOutlined style={{ fontSize: '20px' }} />}
              onClick={() => navigate("/ventas-charts")}
            >
              Inicio
            </Menu.Item>
          ) : (
            ""
          )}
          {access.includes(3) ? (
            <Menu.Item
              key="1"
              icon={<SolutionOutlined style={{ fontSize: '20px' }} />}
              onClick={() => navigate("/ventas")}
            >
              Datos
            </Menu.Item>
          ) : (
            ""
          )}
        </SubMenu>
      ) : (
        ""
      )}
      {access.includes(4) ? (
        <SubMenu key="sub2" icon={<FolderOutlined style={{ fontSize: '20px' }} />} title="OTs">
          {access.includes(5) ? (
            <Menu.Item
              key="2"
              icon={<PieChartOutlined style={{ fontSize: '20px' }} />}
              onClick={() => navigate("/ot-charts")}
            >
              Inicio
            </Menu.Item>
          ) : (
            ""
          )}
          {access.includes(6) ? (
            <Menu.Item
              key="3"
              icon={<SolutionOutlined style={{ fontSize: '20px' }} />}
              onClick={() => navigate("/ot")}
            >
              Datos
            </Menu.Item>
          ) : (
            ""
          )}
          {access.includes(7) ? (
            <Menu.Item
              key="4"
              icon={<TableOutlined style={{ fontSize: '20px' }} />}
              onClick={() => navigate("/ot-osat-report")}
            >
              Reporte OSAT
            </Menu.Item>
          ) : (
            ""
          )}
        </SubMenu>
      ) : (
        ""
      )}
      {access.includes(8) ? (
        <SubMenu key="sub3" icon={<ToolOutlined style={{ fontSize: '20px' }} />} title="Venta Repuestos">
          {access.includes(9) ? (
            <Menu.Item
              key="18"
              icon={<PieChartOutlined style={{ fontSize: '20px' }} />}
              onClick={() => navigate("/repuestos-charts")}
            >
              Inicio
            </Menu.Item>
          ) : (
            ""
          )}
          {access.includes(10) ? (
            <Menu.Item
              key="6"
              icon={<SolutionOutlined style={{ fontSize: '20px' }} />}
              onClick={() => navigate("/repuestos")}
            >
              Datos
            </Menu.Item>
          ) : (
            ""
          )}
          {access.includes(11) ? (
            <Menu.Item
              key="7"
              icon={<DatabaseOutlined style={{ fontSize: '20px' }} />}
              onClick={() => navigate("/stock")}
            >
              Stock
            </Menu.Item>
          ) : (
            ""
          )}
        </SubMenu>
      ) : (
        ""
      )}
      {access.includes(14) ? (
        <SubMenu key="sub5" icon={<UploadOutlined style={{ fontSize: '20px' }} />} title="Cargas">
          {access.includes(22) ? (
            <Menu.Item
              key="14"
              icon={<CloudUploadOutlined style={{ fontSize: '20px' }} />}
              onClick={() => navigate("/file-upload")}
            >
              Subir Archivo
            </Menu.Item>
          ) : (
            ""
          )}
        </SubMenu>
      ) : (
        ""
      )}
      {access.includes(15) ? (
        <SubMenu key="sub6" icon={<UnorderedListOutlined style={{ fontSize: '20px' }} />} title="Bitácora">
          {access.includes(17) ? (
            <Menu.Item
              key="15"
              icon={<LoginOutlined style={{ fontSize: '20px' }} />}
              onClick={() => navigate("/conexion-usuarios")}
            >
              Conexión de usuarios
            </Menu.Item>
          ) : (""
          )}
        </SubMenu>) : (
        ""
      )}
      {access.includes(16) ? (
        <SubMenu key="sub4" icon={<SettingOutlined style={{ fontSize: '20px' }} />} title="Mantenimiento">
          {access.includes(12) ? (
            <Menu.Item
              key="8"
              icon={<UserOutlined style={{ fontSize: '20px' }} />}
              onClick={() => navigate("/users")}
            >
              Usuarios
            </Menu.Item>
          ) : (
            ""
          )}

          {access.includes(18) ? (
            <Menu.Item
              key="9"
              icon={<ProfileOutlined style={{ fontSize: '20px' }} />}
              onClick={() => navigate("/profiles")}
            >
              Perfiles
            </Menu.Item>
          ) : (
            ""
          )}

          {access.includes(19) ? (
            <Menu.Item
              key="10"
              icon={<TagOutlined style={{ fontSize: '20px' }} />}
              onClick={() => navigate("/brands")}
            >
              Marcas
            </Menu.Item>
          ) : (
            ""
          )}

          {access.includes(20) ? (
            <Menu.Item
              key="11"
              icon={<ShopOutlined style={{ fontSize: '20px' }} />}
              onClick={() => navigate("/dealers")}
            >
              Concesionarios
            </Menu.Item>
          ) : (
            ""
          )}

          {access.includes(21) ? (
            <Menu.Item
              key="12"
              icon={<NotificationOutlined style={{ fontSize: '20px' }} />}
              onClick={() => navigate("/notifications")}
            >
              Notificaciones
            </Menu.Item>
          ) : (
            ""
          )}
          {access.includes(23) ? (
            <Menu.Item
              key="23"
              icon={<GrUpdate style={{ fontSize: '20px' }} />}
              onClick={() => navigate("/updates")}
            >
              Actualizaciones
            </Menu.Item>
          ) : (
            ""
          )}
        </SubMenu>
      ) : (
        ""
      )}
    </Menu>
  );

  const customScrollbarStyle = {
    overflowY: 'auto',
    scrollbarWidth: 'none',
    msOverflowStyle: 'none'
  };

  if (isMobile) {
    return (
      <Drawer
        placement="left"
        open={!collapsed}
        onClose={toggleCollapsed}
        width={230}
        bodyStyle={{
          padding: 0,
          background: "linear-gradient(180deg, #001529 0%, #004d80 100%)",
          height: "calc(100% - 70px)",
          marginTop: "70px",
          ...customScrollbarStyle
        }}
        maskStyle={{
          backgroundColor: "rgba(0, 0, 0, 0.45)"
        }}
        headerStyle={{
          display: "none"
        }}
        zIndex={999}
      >
        {menuContent}
      </Drawer>
    );
  }

  return (
    <Sider
      width={230}
      collapsedWidth={0}
      trigger={null}
      collapsible
      collapsed={collapsed}
      onCollapse={toggleCollapsed}
      style={{
        background: "linear-gradient(180deg, #001529 0%, #004d80 100%)",
        overflowY: 'auto',
        position: 'sticky',
        top: 70,
        left: 0,
        zIndex: 999,
        boxShadow: '2px 0 5px rgba(0, 0, 0, 0.1)',
        transition: "all 0.3s ease",
        ...customScrollbarStyle
      }}
    >
      {menuContent}
    </Sider>
  );
};

export default SiderMenu;
