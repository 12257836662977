import React, { useEffect, useState } from "react";
import { Avatar, Badge, Popover } from "antd";
import { BellOutlined } from "@ant-design/icons";
import { NotificationsNormalize } from "./NotificationsNormalize";
import { getNotifications } from "../../redux/slices/notificationsNormalizada";
import { useDispatch, useSelector } from "react-redux";

export const ButtonNotification = ({ user }) => {
  const dispatch = useDispatch();
  const [popoverVisible, setPopoverVisible] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  const notifications = useSelector(
    (state) => state.notificationsNormalizada.notifications.notifications || []
  );

  const totalNotifications = useSelector(
    (state) => state.notificationsNormalizada.notifications.totalNoRead || 0
  );

  const handleViewAllNotifications = () => {
    setPopoverVisible(false);
  };
  useEffect(() => {
    if (user && user.id) {
      setCurrentPage(1);
      dispatch(
        getNotifications({ userIds: user.id, page: 1, count: pageSize })
      );
    }
  }, [dispatch, user]);

  return (
    <Popover
      content={
        <NotificationsNormalize
          notifications={notifications}
          user={user}
          onViewAllNotifications={handleViewAllNotifications}
        />
      }
      title=""
      trigger="click"
      open={popoverVisible}
      onclick={() => setPopoverVisible(!popoverVisible)}
      onOpenChange={(visible) => setPopoverVisible(visible)}
    >
      <a href="#" style={{ marginRight: "20px" }}>
        <Badge size="small" count={totalNotifications}>
          <Avatar
            style={{ backgroundColor: "transparent", justifyContent: "center" }}
            size="default"
            icon={
              <BellOutlined
                twoToneColor="#001529"
                style={{ fontSize: "20px" }}
              />
            }
          />
        </Badge>
      </a>
    </Popover>
  );
};
